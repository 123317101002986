import React from "react"
import useTranslations from "../../../utils/useTranslations"
import FeaturesList from "../../../components/featuresList"
import ComparisonPanels from "../../../components/comparisonPanels"

import heroImgWebp from "../../../images/products/heroes/screen-classic-laptop.webp"
import heroImg from "../../../images/products/heroes/screen-classic-laptop.png"
import heroImgWebp2x from "../../../images/products/heroes/screen-classic-laptop@2x.webp"
import heroImg2x from "../../../images/products/heroes/screen-classic-laptop@2x.png"

import Button from "../../../components/button"
import Logo from "../../../components/logo"
import LocalizedLink from "../../../components/localizedLink"
import backgrounds from "../../../components/backgrounds"

const heroImgAlt = "Download BitTorrent Classic for Desktop"

class BittorrentClassicFree extends React.Component {

  render(){

    const { text, pageName } = this.props.pageContext
    const t = useTranslations(text)
    const { unlocalizedPath }  = this.props.pageContext


    return (
      <>
        
        <div className="top-section-container p-0 pb-md-5 pt-md-3 background-noise-dark" style={backgrounds.darkCorners}>

          <div className="container text-darks py-2">

            <div className="row text-center mt-4 mt-sm-5">
              <div className="col-md-6 order-md-1 px-md-0">
                <Logo color="black" tag="h1" productName="Classic" className="mb-4"/>
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-block d-md-none mb-4"></img>
                </picture>

                <div className="text-size-24 text-spacing-1 font-weight-normal">{t(`The original torrent desktop app.`)}</div>
                <ul className="product-hero-list mt-2">
                	<li>
                		{t(`Fast, bulk torrent downloading from the desktop.`)}
                	</li>
                	<li>
                		{t(`Advanced customization for torrent experts.`)}
                	</li>
                	<li>
                		{t(`Small setup file and light on resources.`)}
                	</li>
                </ul>
                <div>
                  <Button  href={`${unlocalizedPath}#comparison-panels`} id="products-win-classic-free-hero-cta" className="btn-primary text-spacing-1 mt-3 mt-md-3 mb-4 button-filled text-spacing-1">{t(`Free Download`)}</Button>
                </div>
              </div>
              <div className="col-md-6 mb-5 order-md-2">
                {/*<img src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block"></img>*/}

                <picture>
                  <source srcSet={`${heroImgWebp} 1x, ${heroImgWebp2x} 2x`} type="image/webp"></source>
                  <img srcSet={`${heroImg} 1x, ${heroImg2x} 2x`} src={heroImg} alt={heroImgAlt} className="img-fluid d-none d-md-block"></img>
                </picture>

              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden">
          <div className="bg-text-container bg-text-gray">
            {t(`FeaturesHeader`)}
          </div>
          <div className="container mt-5 z-2">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3">{t(`FeaturesHeader`)}</p>
            <p className="text-center mb-5 text-size-24" >
              BitTorrent Classic {t(`Safe Torrenting`)} {t(`Software Features`)}
            </p>
            <div className = "index-features-list m-auto pb-5">
              <FeaturesList text={text} pageName={pageName}/>
            </div>
          </div>
        </div>

        <div className="container-fluid position-relative overflow-hidden">
          <div className="bg-text-container bg-text-gray">
            {t(`Compare`)}
          </div>
      		<div className="container my-5 z-2">
            <p className="text-center font-weight-bold feature-list-title-2 mb-3">{t(`Compare`)}</p>
            <h2 className="text-center mb-5 text-size-24 font-weight-normal">
                {t(`Compare BitTorrent Classic Versions`)}
            </h2>
            <ComparisonPanels pageContext={{ "text": text, "pageName":pageName}} >
              <p className="text-center mt-5">
                <LocalizedLink to="/products/win/bittorrent-classic-premium" id="products-win-classic-free-learnmore-premium" className="font-weight-bold">
                  {t(`Learn more`)}
                </LocalizedLink> {t(`about BitTorrent Classic premium products`)}
              </p>
            </ComparisonPanels>
          </div>
        </div>


        <div className="container my-5 pt-5">
          <div className="word-cloud font-weight-bold">
          	BitTorrent <span className="font-weight-normal">
              Classic
            </span><span className="font-weight-bold">
              / 
            </span> <br/> {t(`Frequently Asked Questions`)}
          </div>
        </div>

        <div className="container my-md-5 py-md-5">
        	<div className="row mb-5 mb-md-5 pb-md-5">
        		<div className="offset-md-1 col-md-2 text-md-right product-faq-question mb-3 mb-md-0">
        			{t(`How does <b>BitTorrent</b> work?`)}
        		</div>
        		<div className="offset-md-1 col-md-8 product-faq-answer">
        			{t(`<b>BitTorrent is a peer-to-peer communications protocol</b> for efficiently sending any type of file, large or small, over the Internet. Safe torrenting software, such as such as BitTorrent Classic, are needed to send or receive files using the protocol. With over 100 million active torrent programs installed on home computers across the world, the protocol enables efficient distribution of files from many recipients to many downloaders. Any file being distributed is segmented into many pieces that eventually make up the complete file. After the process has concluded, those same pieces can be seeded to become a source for other downloaders. In this way, the protocol serves as a decentralized ecosystem, made up of millions of active torrent clients.`)} 
        			<p className="mt-3 mt-md-5">
						{t(`BitTorrent Classic is the best torrent software for the desktop if you’re looking for advanced configuration and the ability to download torrent files in bulk.`)}
        			</p>

        		</div>
        	</div>
        	<div className="row mb-5 mb-md-5 pb-md-5">
        		<div className="offset-md-1 col-md-2 text-md-right product-faq-question mb-3 mb-md-0">
        			{t(`What can I do with <b>BitTorrent?</b>`)}
        		</div>
        		<div className="offset-md-1 col-md-8 product-faq-answer">
        			{t(`<b>In order to send or receive files</b> over the BitTorrent protocol, you need a web or desktop-based torrent client, such as BitTorrent Classic. Once you have identified the torrent file to download, you can click or drag it into your free torrent software. Because the protocol benefits from hundreds of millions of active torrent programs installed on home computers across the world, the distribution of files from many recipients to your home computer is fast and efficient. If you keep your torrent program running, you can seed the same files you’ve received from others, which will share the files with others as they download.`)} 

        		</div>
        	</div>
        	<div className="row mb-5 mb-md-5">
        		<div className="offset-md-1 col-md-2 text-md-right product-faq-question mb-3 mb-md-0">
        			{t(`What is <b>seeding?</b>`)}
        		</div>
        		<div className="offset-md-1 col-md-8 product-faq-answer">
        			{t(`<b>Seeding</b> is another term for sharing files. However, before you can seed, you need a BitTorrent client. The first uploader of a unique file is the seed for that file. Downloaders, also known as peers, can download the file and in turn, act as a seed for others. Because there are hundreds of millions of users with active torrent programs around the world, the number of peers and seeds is vastly abundant, contributing to a healthy torrent ecosystem. BitTorrent Classic has a scheduling feature where both downloading and seeding can be set to run at specific times of day, making it the best torrent downloader available.`)}
        		</div>
        	</div>

        </div>

      </>
    )

  }
}

export default BittorrentClassicFree
